/* eslint-disable */
// import Oidc from 'oidc-client';
import 'babel-polyfill';
import store from "@/core/store";
import { mapState } from "vuex";
import { LOGIN, LOGOUT, REFRESH } from "@/core/store/auth.module";
import { mapGetters } from "vuex";


// var mgr = new Oidc.UserManager({
//   userStore: new Oidc.WebStorageStateStore(),  
//   authority: `${process.env.VUE_APP_IDENTITY_SERVER}`,//https://identity.yardnique.com',
//   client_id: 'dashboard',
//   client_secret: 'secret',
//   redirect_uri: window.location.origin + '/oauth/oauth2-redirect.html',
//   response_type: 'code',//'id_token token', //
//   scope: 'api1 openid profile AspireId AspireRole offline_access',
//   post_logout_redirect_uri: window.location.origin + '/login',
//   silent_redirect_uri: window.location.origin + '/oauth/silent-renew.html',
//   accessTokenExpiringNotificationTime: 60,
//   automaticSilentRenew: true,
//   filterProtocolClaims: true,
//   loadUserInfo: true,
//   // response_mode: "query"
// })

// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.INFO;

// const rootStore = await import('@/core/store')

// var mgr = {
  
// }


// mgr.events.addUserLoaded(function (user) {  
//   console.log('New User Loaded：', arguments);
//   console.log('Acess_token: ', user.access_token)
// });

// mgr.events.addAccessTokenExpiring(function () {
//   console.log('AccessToken Expiring：', arguments);
//   //this.renewToken();
//   mgr.signinSilent().then(function (user) {
//     if (user == null) {
//       // console.log('User is null. Sending to sign in. renewToken');
//       mgr.removeUser();
//       mgr.signinRedirect().catch(function (err) {
//         console.log(err)
//       });
//     } 
//     // else{
//     //   return resolve(user)
//     // }
//   }).catch(function (err) {
//     console.log(err)
//     // return reject(err)
//   });
// });

// mgr.events.addAccessTokenExpired(function () {
//   console.log('AccessToken Expired：', arguments);  
//   //alert('Session expired. Going out!');
//   mgr.signoutRedirect().then(function (resp) {
//     console.log('signed out', resp);
//   }).catch(function (err) {
//     console.log(err)
//   })
// });

// mgr.events.addSilentRenewError(function () {
//   console.error('Silent Renew Error：', arguments);
// });

// mgr.events.addUserSignedOut(function () {
//   //alert('Going out!');
//   console.log('UserSignedOut：', arguments);  
//   mgr.signoutRedirect().then(function (resp) {
//     console.log('signed out', resp);
//   }).catch(function (err) {
//     console.log(err)
//   })
// });

export default class SecurityService {

  // Renew the token manually
  async renewToken () {
    // let self = this
    // rootStore = await import('@/core/store')
    await store.dispatch(REFRESH);
    // return new Promise((resolve, reject) => {
      
    //   mgr.signinSilent().then(function (user) {
    //     if (user == null) {
    //       // console.log('User is null. Sending to sign in. renewToken');
    //       // self.signIn(null)
    //       return resolve(null)
    //     } else{
    //       return resolve(user)
    //     }
    //   }).catch(function (err) {
    //     console.log(err)
    //     return reject(err)
    //   });
    // })
  }

  // Get the user who is logged in
  async getUser () {

    const rootStore = await import('@/core/store')
    return rootStore.default.getters.userInfo;
    // let self = this
    // return new Promise((resolve, reject) => {
    //   mgr.getUser().then(function (user) {
    //     if (user == null) {
    //       // console.log('User is null. Sending to sign in. getUser');
    //       // self.signIn()
    //       return resolve(null)
    //     } else{          
    //       return resolve(user)
    //     }
    //   }).catch(function (err) {
    //     console.log(err)
    //     return reject(err)
    //   });
    // })
  }

  // Check if there is any user logged in
  async getSignedIn () {

    const rootStore = await import('@/core/store')
    if(rootStore.default.getters.userInfo)
      return true;
    return false;
    

    // let self = this
    // return new Promise((resolve, reject) => {
    //   mgr.getUser().then(function (user) {
    //     if (user == null) {
    //       // console.log('User is null. Sending to sign in. getSignedIn');
    //       // self.signIn()
    //       return resolve(false)
    //     } else{
    //       return resolve(true)
    //     }
    //   }).catch(function (err) {
    //     console.log(err)
    //     return reject(err)
    //   });
    // })
  }

  // // Redirect of the current window to the authorization endpoint.
  // signIn () {
  //   mgr.removeUser();
  //   mgr.signinRedirect().catch(function (err) {
  //     console.log(err)
  //   })
  // }
  
  // Redirect of the current window to the end session endpoint
  async signOut () {    
    await store.dispatch(LOGOUT);
    // mgr.signoutRedirect().then(function (resp) {
    //   // console.log('signed out', resp);
    // }).catch(function (err) {
    //   console.log(err)
    // })
  }

  // Get the profile of the user logged in
  async getProfile () {
    const rootStore = await import('@/core/store')
    return rootStore.default.getters.userInfo;
    // let self = this
    // return new Promise((resolve, reject) => {
    //   mgr.getUser().then(function (user) {
    //     if (user == null) {
    //       // console.log('User is null. Sending to sign in. getProfile');
    //       // self.signIn()
    //       return resolve(null)
    //     } else{          
    //       return resolve(user.profile)
    //     }
    //   }).catch(function (err) {
    //     console.log(err)
    //     return reject(err)
    //   });
    // })
  }

  // // Get the token id
  // async getIdToken(){
  //   const rootStore = await import('@/core/store')
  //   return rootStore.default.getters.token;
  //   // let self = this
  //   // return new Promise((resolve, reject) => {
  //   //   mgr.getUser().then(function (user) {
  //   //     if (user == null) {
  //   //       // console.log('User is null. Sending to sign in. getIdToken');
  //   //       // self.signIn()
  //   //       return resolve(null)
  //   //     } else{          
  //   //       return resolve(user.id_token)
  //   //     }
  //   //   }).catch(function (err) {
  //   //     console.log(err)
  //   //     return reject(err)
  //   //   });
  //   // })
  // }

  // // Get the session state
  // getSessionState(){
  //   let self = this
  //   return new Promise((resolve, reject) => {
  //     mgr.getUser().then(function (user) {
  //       if (user == null) {
  //         // console.log('User is null. Sending to sign in. getSessionState');
  //         // self.signIn()
  //         return resolve(null)
  //       } else{          
  //         return resolve(user.session_state)
  //       }
  //     }).catch(function (err) {
  //       console.log(err)
  //       return reject(err)
  //     });
  //   })
  // }

  // Get the access token of the logged in user
  async getAcessToken(){
    const rootStore = await import('@/core/store')
    return rootStore.default.getters.token;
    // let self = this
    // return new Promise((resolve, reject) => {
    //   mgr.getUser().then(function (user) {
    //     if (user == null) {
    //       // console.log('User is null. Sending to sign in. getAccessToken');
    //       // self.signIn()
    //       return resolve(null)
    //     } else{          
    //       return resolve(user.access_token)
    //     }
    //   }).catch(function (err) {
    //     console.log(err)
    //     return reject(err)
    //   });
    // })
  }

  // // Takes the scopes of the logged in user
  // getScopes(){
  //   let self = this
  //   return new Promise((resolve, reject) => {
  //     mgr.getUser().then(function (user) {
  //       if (user == null) {
  //         // console.log('User is null. Sending to sign in. getScopes');
  //         // self.signIn()
  //         return resolve(null)
  //       } else{          
  //         return resolve(user.scopes)
  //       }
  //     }).catch(function (err) {
  //       console.log(err)
  //       return reject(err)
  //     });
  //   })
  // }

  // Get the user roles logged in
  async getRole () {
    const rootStore = await import('@/core/store')
    return rootStore.default.getters.userInfo.role;
    // let self = this
    // return new Promise((resolve, reject) => {
    //   mgr.getUser().then(function (user) {
    //     if (user == null) {
    //       // console.log('User is null. Sending to sign in. getRole');
    //       // self.signIn()
    //       return resolve(null)
    //     } else{          
    //       // return resolve(user.profile.role)
    //       return resolve(user.profile.aspireRole)
    //     }
    //   }).catch(function (err) {
    //     console.log(err)
    //     return reject(err)
    //   });
    // })
  }
}